<template>
  <v-container class="custom-container">

    <v-row class="d-inline-flex flex-wrap flex-lg-nowrap px-2 py-7 justify-space-between" style="width: 100%">

      <v-col class="flex-grow-1 flex-lg-grow-0 order-lg-1 order-1 px-2 px-lg-0">
        <div class="d-inline-flex text-h6 font-weight-medium">
          <v-icon
            v-text="titleIcon"
            color="defaultIconColor"
            class="icon-title mr-2"
            dense
          ></v-icon>
          <div style="width: max-content">{{ $t(title) }}</div>
        </div>
      </v-col>

      <v-col class="flex-grow-0 pl-3 pr-1 order-lg-2 d-none d-lg-block">
        <v-divider vertical> </v-divider>
      </v-col>

      <v-col class="flex-grow-1 order-lg-3 order-3 px-0">
        <Breadcrumbs :items="breadcrumbsItems" maxWidthItem="25%" />
      </v-col>

      <v-col class="flex-grow-0 order-lg-4 order-2 px-0">
        <div class="py-0 text-right">
          <v-btn
            v-for="(action, index) in actions"
            :key="index"
            :color="action.color ? action.color : 'primary'"
            v-on:click="$emit(action.eventToEmit)"
            class="action-btn"
          >
            {{ $t(action.text) }}
          </v-btn>
        </div>
      </v-col>

    </v-row>

    <v-card
      class="card-container"
      outlined
    >
      <v-row class="d-flex d-md-flex-inline flex-column flex-md-row">
        <v-col class="flex-grow-0 d-flex flex-column flex-sm-row flex-md-column align-center align-md-stretch">
          <v-card
            outlined
            class="mr-0 mr-sm-6 mr-md-0"
            width="250"
          >
            <RepositoryCard
              :data="entryData"
              v-if="entryData && entryData.type == 'folder' && !entryData.folder_parent_id"
              outlined
            />

            <FolderCard
              :data="entryData"
              v-else-if="entryData && entryData.type == 'folder'"
              outlined
              @move-success="$emit('move-success', $event)"
            />

            <FileCard
              :data="entryData"
              v-if="entryData && entryData.type == 'file'"
              outlined
              @move-success="$emit('move-success', $event)"
            />
          </v-card>

          <v-list class="info-list transparent mt-0 mt-md-3 flex-grow-1 flex-md-grow-0" dense>
            <v-list-item>
              <v-list-item-subtitle>{{ $t('type') }}</v-list-item-subtitle>

              <v-list-item-title>
                {{ $t(entryData.type) }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-subtitle>{{ $t('creator') }}</v-list-item-subtitle>

              <v-list-item-title>
                {{ onwerName }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-subtitle>{{ $t('size') }}</v-list-item-subtitle>

              <v-list-item-title v-if="entryData && entryData.type == 'file'">
                {{ formattedFileSize }}
              </v-list-item-title>

              <v-list-item-title v-else>
                -
              </v-list-item-title>
            </v-list-item>


            <v-list-item>
              <v-list-item-subtitle>{{ $t('creation_date') }}</v-list-item-subtitle>

              <v-list-item-title>
                {{ formatDate(entryData.created_at) }}
              </v-list-item-title>
            </v-list-item>

            <v-list-item>
              <v-list-item-subtitle>{{ $t('last_modify_date') }}</v-list-item-subtitle>

              <v-list-item-title>
                {{ formatDate(entryData.updated_at) }}
              </v-list-item-title>
            </v-list-item>

          </v-list>
        </v-col>

        <v-col>
          <v-card flat>
            <slot name="table"></slot>
          </v-card>
        </v-col>
      </v-row>
    </v-card>

  </v-container>
</template>

<style scoped>
.card-container {
  padding: 20px;
}

.info-list {
  min-width: 250px;
}

.v-list-item--dense,
.v-list--dense .v-list-item {
  min-height: 35px;
  padding: 0 8px;
}
</style>

<script>
import { mapActions } from "vuex";
import HumanReadable from "@/utils/human_readable";

import AppActions from "@/store/app/actions-types";
import UserActions from "@/store/core/user/actions-types";

import RepositoryCard from '@/components/content/RepositoryCard.vue'
import FolderCard from '@/components/content/FolderCard.vue'
import FileCard from '@/components/content/FileCard.vue'
import Breadcrumbs from "@/components/Breadcrumbs.vue";

export default {
  name: "ActionViewContainer",

  components: {
    RepositoryCard,
    FolderCard,
    FileCard,
    Breadcrumbs,
  },

  props: {
    breadcrumbsItems: {
      type: Array,
    },

    titleIcon: {
      type: String,
    },

    title: {
      type: String,
    },

    actions: {
      type: Array,
    },

    entryData: {
      type: Object,
    },
  },

  data() {
    return {
      userInfo: {},
    };
  },

  watch: {
    entryData: {
      handler(newVal, oldVal) {
        let usersId = newVal.users_id;

        if (!usersId) {
          oldVal.users_id;
        }

        this.fetchUser(usersId);
      },
    },
  },

  computed: {
    formattedFileSize() {
      return HumanReadable.fileSize(this.entryData.filesize);
    },

    onwerName() {
      if (!this.userInfo) {
        return "-";
      }

      return this.userInfo.name;
    },
  },

  methods: {
    ...mapActions("app", [
      AppActions.OPEN_APP_INFO_MESSAGE,
      AppActions.OPEN_APP_SUCCESS_MESSAGE,
    ]),

    ...mapActions("core/user", [UserActions.GET_USER]),

    fetchUser(usersId) {
      this[UserActions.GET_USER](usersId).then((result) => {
        this.userInfo = result;
      });
    },

    formatDate(dateStr) {
      if (!dateStr) {
        return "-";
      }

      return HumanReadable.dateTime(dateStr);
    },
  },
};
</script>
